import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';

import Alert from '@/core/components/Alert';
import Button from '@/core/components/Button';
import FormContainer from '@/core/components/FormContainer';
import Header from '@/core/components/Header';
import HintMessage from '@/core/components/HintMessage';
import CheckboxInput from '@/core/components/inputs/CheckboxInput';
import CityInput from '@/core/components/inputs/CityInput';
import PasswordInput from '@/core/components/inputs/PasswordInput';
import NewTextInput from '@/core/components/inputs/TextInput/NewTextInput';
import Label from '@/core/components/Label';
import useForm from '@/core/hooks/useForm';
import useKeyDownHandler from '@/core/hooks/useKeyDownHandler';
import { AnalyticsEventType, useAnalyticsContext } from '@/core/lib/analytics/analytics.context';
import { useAuthContext } from '@/core/lib/auth/auth.context';
import { useEnvContext } from '@/core/lib/env/env.context';
import { ApiMethods } from '@/core/lib/fetch';
import { ApiVersions } from '@/core/lib/fetch/fetch';
import { FFName, useFFContext } from '@/core/lib/ff/ff.context';
import { focusInputs, transformValuesToFocusMapping } from '@/core/lib/form';
import Actions from '@/core/lib/new-architecture/actions';
import { useTranslationContext } from '@/core/lib/translation/translation.context';
import { AuthRoutes, useAuthRoutesContext } from '@/core/pages/auth/Authentification';
import type { PageWithLayoutsAndProviders } from '@/core/pages/page.types';
import type { UsersAuthRegisterForm, UsersAuthResult } from '@/core/types/auth';
import { usersAuthRegisterFormSchema } from '@/core/types/auth';

const Register: PageWithLayoutsAndProviders = () => {
  const { DONNONS_API_URL } = useEnvContext();
  const { t } = useTranslationContext(['auth.register', 'common']);
  const { back, onClose, go, authRegisterFormValues } = useAuthRoutesContext();
  const { login } = useAuthContext();
  const { send, getUtmOrigin } = useAnalyticsContext();
  const { ff } = useFFContext();

  const { onSubmit: patchNewsletter } = Actions.user.usePatchNewsletter({ onSuccess: () => {} });

  const { control, onSubmit, errors, onCleanGlobalError, isLoading, setFocus, getValues, setValue } = useForm<UsersAuthResult, UsersAuthRegisterForm>({
    schema: usersAuthRegisterFormSchema,
    apiDefinition: { method: ApiMethods.POST, url: `${DONNONS_API_URL}${ApiVersions.V1}/signup` },
    transformers: {
      formToApi: form => ({ ...form, loc: form.location.loc, newsletter: !form.newsletter, push: !form.push, origin: getUtmOrigin() }),
    },
    onSuccess: async data => {
      await login({
        accessToken: data.access_token,
        accessExpiresAt: dayjs().add(data.access_expires_in, 'second'),
        refreshToken: data.refresh_token ?? null,
        refreshExpiresAt: data.refresh_expires_in ? dayjs().add(data.refresh_expires_in, 'second') : null,
      });

      await patchNewsletter({ push: !getValues('push'), newsletter: !getValues('newsletter') });
      await send({ event: AnalyticsEventType.SIGN_UP_COMPLETE, origin: 'email' });
      onClose({ success: true });
    },
  });

  useEffect(() => {
    if (authRegisterFormValues) {
      Object.entries(authRegisterFormValues).forEach(([key, value]) => {
        setValue(key as keyof UsersAuthRegisterForm, value);
      });
    }
  }, [authRegisterFormValues]);

  useKeyDownHandler([], 'Enter', () => {
    const { activeElement } = document;
    if (activeElement) {
      const values = getValues() satisfies UsersAuthRegisterForm;
      const inputsFocusMapping = transformValuesToFocusMapping<UsersAuthRegisterForm>(values);
      const inputToFocus = focusInputs<UsersAuthRegisterForm>(activeElement as HTMLInputElement, inputsFocusMapping);
      if (inputToFocus) {
        setFocus(inputToFocus);
      }
    }
  });

  const handleGo = () => {
    const values = getValues();
    go(AuthRoutes.CGU, { ...values, password: '' });
  };

  return (
    <div className="flex h-full flex-col gap-4 md:gap-7">
      <Header content={t('title', { ns: 'auth.register' })} iconBefore="arrow-left" onBefore={back} />
      <div className="inline-flex h-full flex-col pt-3">
        {errors.global.isError && errors.global.message && (
          <Alert status="error" onClose={onCleanGlobalError}>
            {t(errors.global.message, { ns: 'common' })} {errors.global.code}
          </Alert>
        )}

        <FormContainer>
          <Controller
            control={control}
            defaultValue=""
            render={({ field: { ref, onChange, onBlur, value } }) => {
              const { isError, message, isSuccess } = errors.username;
              return (
                <Label htmlFor="username" content={t('form.username.label', { ns: 'auth.register' })}>
                  <NewTextInput
                    id="username"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    placeholder={t('form.username.placeholder', { ns: 'auth.register' })}
                    autoComplete="username"
                    inputMode="text"
                    validation={{ isError, isSuccess }}
                    ref={ref}
                    enterKeyHint="next"
                  />
                  {!isError && <HintMessage message={t('inputs.username.hint', { ns: 'common' })} />}
                  {isError && <HintMessage isError={isError} isSuccess={isSuccess} message={message} />}
                </Label>
              );
            }}
            name="username"
          />

          <Controller
            control={control}
            defaultValue=""
            render={({ field: { ref, onChange, onBlur, value } }) => {
              const { isError, message, isSuccess } = errors.email;
              return (
                <Label htmlFor="email" content={t('form.email.label', { ns: 'auth.register' })}>
                  <NewTextInput
                    id="email"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    placeholder={t('form.email.placeholder', { ns: 'auth.register' })}
                    autoComplete="email"
                    inputMode="email"
                    validation={{ isError, isSuccess }}
                    ref={ref}
                    enterKeyHint="next"
                  />
                  <HintMessage isError={isError} isSuccess={isSuccess} message={message} />
                </Label>
              );
            }}
            name="email"
          />

          <Controller
            control={control}
            defaultValue=""
            render={({ field: { ref, onChange, onBlur } }) => {
              const { isError, message, isSuccess } = errors.password;
              return (
                <Label htmlFor="password" content={t('form.password.label', { ns: 'auth.register' })}>
                  <PasswordInput
                    id="password"
                    onChange={onChange}
                    onBlur={onBlur}
                    placeholder={t('form.password.placeholder', { ns: 'auth.register' })}
                    autocomplete="new-password"
                    validation={{ isError, isSuccess }}
                    ref={ref}
                    enterKeyHint="next"
                  />
                  {!isError && <HintMessage message={t('inputs.password.hint', { ns: 'common' })} />}
                  {isError && <HintMessage isError={isError} isSuccess={isSuccess} message={message} />}
                </Label>
              );
            }}
            name="password"
          />

          <Controller
            control={control}
            render={({ field: { ref, onChange, onBlur, value } }) => {
              const { isError, message, isSuccess } = errors.location;
              return (
                <Label htmlFor="location" content={t('form.location.label', { ns: 'auth.register' })}>
                  <CityInput onChange={onChange} onBlur={onBlur} id="location" value={value} placeholder={t('form.location.placeholder', { ns: 'auth.register' })} ref={ref} type="limited" />
                  <HintMessage isError={isError} isSuccess={isSuccess} message={message} />
                </Label>
              );
            }}
            name="location"
          />

          <div className="py-3">
            <Controller
              control={control}
              defaultValue={false}
              render={({ field: { onChange, onBlur, value } }) => {
                const { isError, message, isSuccess } = errors.cgu;
                return (
                  <>
                    <CheckboxInput onChange={onChange} onBlur={onBlur} id="cgu" value={value} validation={{ isError, isSuccess }}>
                      {t('form.cgu.label', { ns: 'auth.register' })}&nbsp;
                      <button type="button" onClick={handleGo} className="text-body-secondary !p-0 text-center text-content-secondary underline">
                        {t('form.cgu.cgu', { ns: 'auth.register' })}
                      </button>
                      &nbsp;{t('form.cgu.and', { ns: 'auth.register' })}&nbsp;
                      <button type="button" onClick={handleGo} className="text-body-secondary !p-0 text-center text-content-secondary underline">
                        {t('form.cgu.privacy', { ns: 'auth.register' })}
                      </button>
                    </CheckboxInput>
                    {isError && <HintMessage isError={isError} isSuccess={isSuccess} message={message} />}
                  </>
                );
              }}
              name="cgu"
            />

            <Controller
              control={control}
              defaultValue={false}
              render={({ field: { onChange, onBlur, value } }) => {
                const { isError, isSuccess } = errors.newsletter;
                return (
                  <CheckboxInput onChange={onChange} onBlur={onBlur} id="newsletter" value={value} validation={{ isError, isSuccess }}>
                    {t('form.newsletter.label', { ns: 'auth.register' })}
                  </CheckboxInput>
                );
              }}
              name="newsletter"
            />
            {ff(FFName.NotifMarket) && (
              <Controller
                control={control}
                defaultValue={false}
                render={({ field: { onChange, onBlur, value } }) => {
                  const { isError, isSuccess } = errors.newsletter;
                  return (
                    <CheckboxInput onChange={onChange} onBlur={onBlur} id="push" value={value} validation={{ isError, isSuccess }}>
                      {t('form.push.label', { ns: 'auth.register' })}
                    </CheckboxInput>
                  );
                }}
                name="push"
              />
            )}
          </div>
        </FormContainer>

        <div className="mt-auto flex w-full justify-end">
          <div className="max-w-max">
            <Button type="submit" isLoading={isLoading} onClick={onSubmit}>
              {t('form.submit', { ns: 'auth.register' })}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
